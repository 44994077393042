/*
 * GCommerce Scripts File
 * Author: GCommerce Solutions
 *
 * This file should contain any js scripts you want to add to the site.
 */

jQuery(document).ready(function($) {

	// ********************************
	//         MOBILE MENU 
	// ********************************

	$('.js-menu-trigger').on('click touchstart', function(e){
		// Set the top position of the mobile menu to the bottom of the header element.
		var $element = $('.header-wrapper');  //record the elem so you don't crawl the DOM everytime  
		var bottom = $element.outerHeight(true) + $('#wpadminbar').outerHeight(true);
		console.log('menu bottom = ' + bottom);
		$('.sliding-menu-content').css('margin-top', 0);
		$('.sliding-menu-content').css('top', bottom);
		$('.sliding-menu-content').slideToggle();
		$(this).toggleClass('open');
		e.preventDefault();
	});

	$('.js-menu-screen').on('click touchstart', function(e){
		// $('.js-menu').toggleClass('is-visible');
		// $('.js-menu-screen').toggleClass('is-visible');
		// $('.click-out').toggle();
		e.preventDefault();
	});


	/********************************
	 SELECTRIC IN HEADER
	*********************************/
	$('#property-selection-header').selectric({
		// inheritOriginalWidth: true,
		onChange: function(element) {
			var urlParams = new URLSearchParams(window.location.search);
			urlParams.set('property_id', $(element).val());
			urlParams.set('property_name', $('#property-selection-header option:selected').text());
			window.location.href = '/?' + urlParams;
			return false;
		},
	});

	/********************************
	 SELECTRIC ON DASHBOARD
	*********************************/
	$('#property-selection').selectric({
		// inheritOriginalWidth: true,
		onChange: function(element) {
			var urlParams = new URLSearchParams(window.location.search);
			urlParams.set('property_id', $(element).val());
			urlParams.set('property_name', $('#property-selection option:selected').text());
			window.location.href = '/dashboard?' + urlParams;
			return false;
		},
	});

	/********************************
	 SECTION SLIDE IN ANIMATION
	*********************************/

	var isAnimatable = window.matchMedia("only screen and (max-width: 1024px)");
	$(window).on('scroll load', function() {
		$('.animation-element').each(function(i) {
			var objectBottom = $(this).offset().top;
			var windowBottom = $(window).scrollTop() + $(window).height();

			if (!isAnimatable.matches && windowBottom > objectBottom) {
			$(this).animate({
				'opacity': '1',
				'top': '0'
			}, 1000);

			}

		});
	});

	/********************************
	 ICONS FOR LOGIN FORM
	*********************************/

	$("#user_login").before('<img src="/wp-content/themes/gcomclient/assets/images/user-light.svg" alt="Generic person icon">');
	$("#user_pass").before('<img src="/wp-content/themes/gcomclient/assets/images/lock-alt-regular.svg" alt="Generic person icon">');

	/********************************
	 REPORTS NAV POSITIONING & FUNCTION
	*********************************/
	if ( $('#wpadminbar').length ) { // There is an admin bar present
		var wpadminBarHeight = $('#wpadminbar').height();
	} else {
		var wpadminBarHeight = 0;
	}
	var navigation = $("header");
	var headerHeight = $("header").height();
	var report_nav_top_offset = wpadminBarHeight + headerHeight;

	// $('#reports-nav').css('top', report_nav_top_offset);

	$('#reports-nav li').on('click touchstart', function(element){
		// console.log('data-report-id=' + $(this).attr("data-report-id"));
		if ( $(this).children('a').length < 1 ) {
			element.preventDefault();
			var urlParams = new URLSearchParams(window.location.search);
			if ( urlParams.has('report_id') ) { 
				urlParams.set('report_id', $(this).attr("data-report-id"));
			} else {
				urlParams.append('report_id', $(this).attr("data-report-id"));
			}
			window.location.href = '/report?' + urlParams;
		}
	});

	// Make the reports nav full height.
	// $('#reports-nav').css('height', $('body').height() - report_nav_top_offset);


	/********************************
	 INVOICES PAGINATION ON BILLING REPORT
	*********************************/
	var allBillingBtns = $('.metadesk-billing-invoices-wrapper .invoice-page-btn');
    allBillingBtns.first().addClass('active');
    var allBillingLinks = $('.metadesk-billing-invoices-wrapper .metadesk-billing-invoice-link');
    allBillingLinks.slice(0,3).addClass('active');
    allBillingBtns.click(function(){
        allBillingBtns.removeClass('active');
        $(this).addClass('active');
        allBillingLinks.removeClass('active');
        var thisInvoicePage = $(this).data('page-btn');
        var endingInvoice = thisInvoicePage*3;
        var startingInvoice = endingInvoice - 3;
        allBillingLinks.slice(startingInvoice,endingInvoice).addClass('active');
    });

	/********************************
	 BILLING ALERT ON ALL REPORTS PAGE
	*********************************/
	$('.metadesk-dashboard-billing-close').on('click', function() {
		$('.metadesk-dashboard-billing-wrapper').slideUp();
		Cookies.set('hide_billing_notice', 'yes', { expires: 30, path: '/' });
	});

}); /* end of as page load scripts */
